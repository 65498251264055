import { getFormProps, getInputProps, useForm } from '@conform-to/react'
import { getZodConstraint, parseWithZod } from '@conform-to/zod'
import { type SEOHandle } from '@nasa-gcn/remix-seo'
import {
	json,
	type ActionFunctionArgs,
	type LoaderFunctionArgs,
	type MetaFunction,
} from '@remix-run/node'
import { Form, Link, useActionData, useSearchParams } from '@remix-run/react'
import { HoneypotInputs } from 'remix-utils/honeypot/react'
import { z } from 'zod'
import { GeneralErrorBoundary } from '#app/components/error-boundary.tsx'
import { CheckboxField, ErrorList, Field } from '#app/components/forms.tsx'
import { Logo } from '#app/components/logo.tsx'
import { buttonVariants } from '#app/components/ui/button.js'
import { StatusButton } from '#app/components/ui/status-button.tsx'
import { login, requireAnonymous } from '#app/utils/auth.server.ts'
import {
	ProviderConnectionForm,
	providerNames,
} from '#app/utils/connections.tsx'
import { checkHoneypot } from '#app/utils/honeypot.server.ts'
import { cn, useIsPending } from '#app/utils/misc.tsx'
import { EmailSchema, PasswordSchema } from '#app/utils/user-validation.ts'
import { handleNewSession } from './login.server.ts'

export const handle: SEOHandle = {
	getSitemapEntries: () => null,
}

const LoginFormSchema = z.object({
	email: EmailSchema,
	password: PasswordSchema,
	redirectTo: z.string().optional(),
	remember: z.boolean().optional(),
})

export async function loader({ request }: LoaderFunctionArgs) {
	await requireAnonymous(request)
	return json({})
}

export async function action({ request }: ActionFunctionArgs) {
	await requireAnonymous(request)
	const formData = await request.formData()
	checkHoneypot(formData)
	const submission = await parseWithZod(formData, {
		schema: (intent) =>
			LoginFormSchema.transform(async (data, ctx) => {
				if (intent !== null) return { ...data, session: null }

				const session = await login(data)
				if (!session) {
					ctx.addIssue({
						code: z.ZodIssueCode.custom,
						message: 'Invalid email or password',
					})
					return z.NEVER
				}

				return { ...data, session }
			}),
		async: true,
	})

	if (submission.status !== 'success' || !submission.value.session) {
		return json(
			{ result: submission.reply({ hideFields: ['password'] }) },
			{ status: submission.status === 'error' ? 400 : 200 },
		)
	}

	const { session, remember, redirectTo } = submission.value

	return handleNewSession({
		request,
		session,
		remember: remember ?? false,
		redirectTo,
	})
}

export default function LoginPage() {
	const actionData = useActionData<typeof action>()
	const isPending = useIsPending()
	const [searchParams] = useSearchParams()
	const redirectTo = searchParams.get('redirectTo')

	const [form, fields] = useForm({
		id: 'login-form',
		constraint: getZodConstraint(LoginFormSchema),
		defaultValue: { redirectTo },
		lastResult: actionData?.result,
		onValidate({ formData }) {
			return parseWithZod(formData, { schema: LoginFormSchema })
		},
		shouldRevalidate: 'onBlur',
	})

	return (
		<>
			<div className="mb-6 text-h4">
				<Logo />
			</div>
			<div className="mb-6 flex flex-col gap-3">
				<h1 className="text-h1">Login</h1>
				<p className="text-body-md text-muted-foreground">Welcome back!</p>
			</div>

			<div>
				<div className="mx-auto w-full max-w-md">
					<Form method="POST" {...getFormProps(form)}>
						<HoneypotInputs />
						<Field
							labelProps={{ children: 'Email' }}
							inputProps={{
								...getInputProps(fields.email, { type: 'text' }),
								autoFocus: true,
								className: 'lowercase',
								autoComplete: 'email',
							}}
							errors={fields.email.errors}
						/>

						<Field
							labelProps={{ children: 'Password' }}
							inputProps={{
								...getInputProps(fields.password, {
									type: 'password',
								}),
								autoComplete: 'current-password',
							}}
							errors={fields.password.errors}
						/>

						<div className="flex items-center justify-between">
							<CheckboxField
								labelProps={{
									htmlFor: fields.remember.id,
									children: 'Remember me',
								}}
								buttonProps={getInputProps(fields.remember, {
									type: 'checkbox',
								})}
								errors={fields.remember.errors}
							/>
							<div>
								<Link to="/forgot-password" className="link-hover text-body-sm">
									Forgot password?
								</Link>
							</div>
						</div>

						<input {...getInputProps(fields.redirectTo, { type: 'hidden' })} />
						<ErrorList errors={form.errors} id={form.errorId} />

						<div className="flex items-center justify-between gap-6 pt-3">
							<StatusButton
								className="w-full"
								status={isPending ? 'pending' : (form.status ?? 'idle')}
								type="submit"
								disabled={isPending}
							>
								Log in
							</StatusButton>
						</div>
					</Form>
					<ul className="mt-5 flex flex-col gap-5 border-b border-t border-border py-5">
						{providerNames.map((providerName) => (
							<li key={providerName}>
								<ProviderConnectionForm
									type="Login"
									providerName={providerName}
									redirectTo={redirectTo}
								/>
							</li>
						))}
					</ul>
					<div className="flex items-center justify-center pt-6">
						<Link
							to={
								redirectTo
									? `/signup?redirectTo=${encodeURIComponent(redirectTo)}`
									: '/signup'
							}
							className={cn(buttonVariants({ variant: 'secondary' }), 'w-full')}
						>
							Create an account
						</Link>
					</div>
				</div>
			</div>
		</>
	)
}

export const meta: MetaFunction = () => {
	return [{ title: 'Login to Social Saves' }]
}

export function ErrorBoundary() {
	return <GeneralErrorBoundary />
}
